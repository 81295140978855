<script setup lang="ts">
import { vConfetti } from "@neoconfetti/vue";
import type { FormStore } from "~/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { buttonVariants } from "../ui/button";
import { useTimeAgo } from "@vueuse/core";

const { user } = useUserSession();
const { handleMouseOver, handleMouseOut } = useEditorScrollToggle();
const { forms } = useFormStore();
const system = useSystemStore();
const {
  editorHistoryOpen,
  editorPreviewOpen,
  editorCanvasOpen,
  viewportWidth,
} = storeToRefs(system);
const id = computed(() => useRoute().params.id as string);
const form = computed<FormStore | undefined>(() => forms[id.value]);

const modeSelectionButtonStyles = `
  bg-transparent
  min-[400px]:flex
  text-slate-500
  hover:text-pink-500
  data-[active=true]:text-pink-500
  transition-all
  duration-300
  py-2
  px-2.5
  relative
  z-10
`;

const activeIndicatorPosition = ref(0);
const activeIndicatorWidth = ref(0);
const activeIndicatorIndex = ref(0);
const modeButtons = ref<HTMLElement[]>([]);

const hasBeenPublished = computed(() => {
  return !!form.value?.history?.find((h) => h.published);
});

const launchConfetti = ref<boolean | undefined>(false);
const allowAnimate = ref(true);

function updateActiveIndicator(index: number) {
  activeIndicatorIndex.value = index;
  const button = modeButtons.value[index];
  if (!button) return;

  activeIndicatorPosition.value = button.offsetLeft;
  activeIndicatorWidth.value = button.offsetWidth;
}

function handleEditToggle() {
  editorHistoryOpen.value = false;
  editorPreviewOpen.value = false;
  editorCanvasOpen.value = true;
  updateActiveIndicator(0);
}
function handlePreviewToggle() {
  editorPreviewOpen.value = true;
  editorCanvasOpen.value = false;
  editorHistoryOpen.value = false;
  updateActiveIndicator(1);
}
function handleHistoryToggle() {
  editorHistoryOpen.value = true;
  editorPreviewOpen.value = false;
  editorCanvasOpen.value = false;
  updateActiveIndicator(2);
}

watch(editorCanvasOpen, () => {
  if (editorCanvasOpen.value) {
    setTimeout(() => {
      handleEditToggle();
    }, 10);
  }
});
watch(editorPreviewOpen, () => {
  if (editorPreviewOpen.value) {
    setTimeout(() => {
      handlePreviewToggle();
    }, 10);
  }
});
watch(editorHistoryOpen, () => {
  if (!editorHistoryOpen.value) {
    if (editorPreviewOpen.value) {
      updateActiveIndicator(1);
    } else {
      updateActiveIndicator(0);
    }
  } else {
    updateActiveIndicator(2);
  }
});

watch(viewportWidth, () => {
  updateActiveIndicator(activeIndicatorIndex.value);
});

async function publish() {
  if (!form.value) return;

  editorHistoryOpen.value = false;

  if (!form.value?.isPublished) {
    await form.value.publish();
    launchConfetti.value = true;
    if (form.value?.isPublished) {
      allowAnimate.value = false;
    }
  }
}

function resetAnimation() {
  if (form.value?.isPublished) return;
  allowAnimate.value = true;
}

const buttonClasses = cn(buttonVariants({ variant: "default", size: "sm" }));

onMounted(() => {
  updateActiveIndicator(0);
});
</script>

<template>
  <SiteHeaderContainer
    id="editor-header"
    :full-width="true"
    class="!shadow-none !border-gray-300 h-[50px] !bg-white"
  >
    <div class="flex items-center gap-2 grow shrink-0">
      <NuxtLink
        v-tooltip="{
          content: `<span class='block text-xs'>Go to your Dashboard</span>`,
          html: true,
          hideTriggers: ['hover'],
        }"
        class="flex items-center cursor-pointer p-2 -ml-2 border border-transparent hover:bg-pink-50 hover:border-pink-400 rounded"
        to="/dashboard"
      >
        <Logo :mark-only="true" />
      </NuxtLink>

      <span
        class="hidden lg:inline-block ml-2 text-lg lg:text-xl text-slate-600"
        >{{ form?.title || "Loading Form..." }}</span
      >
      <span class="text-xs mt-auto mb-[10px] text-slate-500">
        <template v-if="form?.loading">
          <div class="flex items-center gap-1 -mt-0.5">
            <Loader class="w-3 h-3" />
            Loading...
          </div>
        </template>
        <template
          v-else-if="form?.history?.[form?.history?.length - 1]?.created_at"
        >
          Saved:
          {{
            useTimeAgo(
              form?.history?.[form?.history?.length - 1]?.created_at + "Z"
            ).value
          }}
        </template>
        <template v-else>
          <div class="flex items-center gap-1 -mt-0.5">
            <Loader class="w-3 h-3" />
            Saving...
          </div>
        </template>
      </span>
    </div>
    <div class="mr-auto flex items-center gap-0 shrink" @click.stop.prevent>
      <div
        class="editor-mode-selector relative flex items-center justify-center bg-slate-50 overflow-hidden rounded ring-1 ring-slate-300"
      >
        <div
          class="absolute bg-white rounded-sm ring-1 ring-inset ring-slate-300 -translate-x-px transition-all duration-300 ease-out shadow z-0 transform-gpu"
          :style="{
            left: activeIndicatorPosition
              ? `${activeIndicatorPosition}px`
              : '0',
            width: activeIndicatorWidth
              ? `${activeIndicatorWidth + 2}px`
              : '30%',
            height: 'calc(100% + 2px)',
          }"
        />
        <button
          :ref="(el) => (modeButtons[0] = el as HTMLElement)"
          :class="[
            modeSelectionButtonStyles,
            { 'text-pink-500': !editorPreviewOpen && !editorHistoryOpen },
          ]"
          :data-active="!editorPreviewOpen && !editorHistoryOpen"
          @click="handleEditToggle"
          id="edit-button"
        >
          <Icon
            class="lg:mr-1.5 h-5 w-5 -ml-0.5"
            name="material-symbols:edit-outline"
          />
          <span class="text-xs pr-1 sm:text-sm hidden lg:block">Edit</span>
        </button>

        <button
          :ref="(el) => (modeButtons[1] = el as HTMLElement)"
          :class="[
            modeSelectionButtonStyles,
            { 'text-pink-500': editorPreviewOpen },
          ]"
          :data-active="editorPreviewOpen"
          @click="handlePreviewToggle"
          id="preview-button"
        >
          <Icon
            class="lg:mr-1 h-5 w-5 -ml-0.5"
            name="material-symbols:preview"
          />
          <span class="text-xs sm:text-sm hidden lg:block">Preview</span>
        </button>

        <button
          :ref="(el) => (modeButtons[2] = el as HTMLElement)"
          :class="[
            modeSelectionButtonStyles,
            { 'text-pink-500': editorHistoryOpen },
          ]"
          :data-active="editorHistoryOpen"
          @click="handleHistoryToggle"
          id="history-button"
        >
          <Icon
            class="lg:mr-1 w-5 h-5 mx-auto cursor-pointer"
            name="material-symbols:history"
          />
          <div class="hidden lg:flex flex-col leading-none text-left">
            <span class="text-xs sm:text-sm">
              <span class="hidden lg:inline">Revert</span>
            </span>
          </div>
        </button>
      </div>
    </div>

    <div class="border-l border-slate-300 pl-5 flex items-center gap-2">
      <NuxtLink to="#form-settings">
        <Button
          size="sm"
          class="!bg-slate-50 hidden min-[400px]:flex ring-1 !p-2.5 ring-inset ring-slate-300 hover:!bg-slate-200 !text-slate-600/80"
        >
          <Icon
            class="lg:mr-1.5 w-5 h-5 mx-auto cursor-pointer"
            name="material-symbols-light:settings"
          />
          <span class="text-xs sm:text-sm hidden lg:block">Settings</span>
        </Button>
      </NuxtLink>
      <NuxtLink :to="`/dashboard/${id}/results`">
        <Button
          size="sm"
          class="!bg-slate-50 hidden min-[400px]:flex ring-1 !p-2.5 ring-inset ring-slate-300 hover:!bg-slate-200 !text-slate-600"
        >
          <Icon
            class="lg:mr-1.5 w-5 h-4 mx-auto cursor-pointer"
            name="lucide:table"
          />
          <span class="text-xs sm:text-sm hidden lg:block">Results</span>
        </Button>
      </NuxtLink>

      <div
        class="lg:pl-5 lg:ml-2.5 lg:border-l border-slate-300 flex items-center gap-2"
      >
        <div class="flex items-center gap-2">
          <ClientOnly>
            <DropdownMenu>
              <DropdownMenuTrigger
                :class="`${
                  hasBeenPublished
                    ? ''
                    : 'pointer-events-none cursor-not-allowed'
                }`"
              >
                <Button
                  size="sm"
                  :class="`
                ${buttonClasses}
                ${
                  form?.isPublished
                    ? '!bg-pink-600 hover:!bg-pink-700'
                    : '!bg-slate-50 ring-1 ring-inset ring-slate-300 hover:!bg-slate-200 !text-slate-600'
                }
              `"
                  :disabled="!hasBeenPublished"
                >
                  <Icon
                    class="lg:mr-2 h-4 w-4 sm:h-4 sm:w-4"
                    name="material-symbols:share-outline"
                  />
                  <span class="text-xs sm:text-sm hidden lg:block">Share</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <input
                  type="text"
                  class="w-full"
                  :value="`${useRuntimeConfig().public.appUrl}/${id}`"
                />
              </DropdownMenuContent>
            </DropdownMenu>
          </ClientOnly>
          <Button
            size="sm"
            id="publish-button"
            :class="
              !form || form?.loading
                ? '!bg-slate-200 !text-slate-600'
                : form?.isPublished
                ? '!bg-slate-600'
                : '!bg-pink-600 hover:!bg-pink-700 !text-slate-100'
            "
            :disabled="form?.isPublished"
            @click="publish"
            @mouseleave="resetAnimation"
          >
            <div class="absolute -top-0 left-1/2 pointer-events-none" />
            <Loader v-if="!form || form?.loading" class="mr-2" />
            <Icon
              v-else
              class="mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4"
              name="material-symbols:rocket-launch"
            />
            <span class="text-xs sm:text-sm">{{
              form?.publishing
                ? "Publishing..."
                : form && !form.loading
                ? form?.isPublished
                  ? "Published"
                  : "Publish"
                : "Loading..."
            }}</span>
          </Button>
        </div>
      </div>

      <div class="hidden lg:block pl-5 ml-2.5 border-l border-slate-300">
        <NuxtLink
          v-if="!user"
          class="font-semibold text-sm sm:text-base text-gray-500 hover:text-pink-600 hidden sm:block"
          to="/login"
        >
          <Button variant="outline" class="text-slate-600"> Log in </Button>
        </NuxtLink>
        <UserNavigation v-else>
          <Avatar />
        </UserNavigation>
      </div>
    </div>
  </SiteHeaderContainer>
</template>
