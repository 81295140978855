<script setup lang="ts">
import { ConfigProvider } from "radix-vue";
import "@/assets/css/filters.css";
import "@/assets/css/tiptap.css";

useHead({
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
    },
  ],
});

const useIdFunction = () => useId();
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <div
      :class="`
    flex
    flex-col
    min-w-[100svw]
    max-w-[100svw]
    min-h-[100svh]
    max-h-[100svh]
    overflow-clip
  `"
    >
      <DissolveEffectInitializer />
      <EditorSiteHeader />
      <main class="flex relative justify-center flex-1 w-full h-full">
        <slot />
      </main>
      <NewFormModal />
      <Toast />
      <ScrollBarWidthCalculator />
    </div>
  </ConfigProvider>
</template>

<style scoped>
:global(body:has(#editor-canvas)) {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100lvw;
  max-height: 100lvh;
}
:global(html:has(#editor-canvas)) {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100lvw;
  max-height: 100lvh;
}
:global(#__nuxt:has(#editor-canvas)) {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100lvw;
  max-height: 100lvh;
}

:global(body:has(#editor-canvas)) * {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
</style>
