<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
    <defs>
      <filter
        id="dissolve-filter"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        color-interpolation-filters="sRGB"
        overflow="visible"
      >
        <!-- Generate large-scale fractal noise -->
        <feTurbulence
          id="dissolve-filter-turbulence"
          type="fractalNoise"
          baseFrequency="0.005"
          numOctaves="1"
          result="bigNoise"
        />

        <!-- Enhance noise contrast -->
        <feComponentTransfer in="bigNoise" result="bigNoiseAdjusted">
          <feFuncR type="linear" slope="3" intercept="-1" />
          <feFuncG type="linear" slope="3" intercept="-1" />
        </feComponentTransfer>

        <!-- Generate fine-grained fractal noise -->
        <feTurbulence
          type="fractalNoise"
          baseFrequency="0.5"
          numOctaves="1"
          result="fineNoise"
        />

        <!-- Merge the adjusted big noise and fine noise -->
        <feMerge result="mergedNoise">
          <feMergeNode in="bigNoiseAdjusted" />
          <feMergeNode in="fineNoise" />
        </feMerge>

        <!-- Apply displacement map to distort the element -->
        <feDisplacementMap
          id="dissolve-filter-displacement"
          in="SourceGraphic"
          in2="mergedNoise"
          scale="0"
          xChannelSelector="R"
          yChannelSelector="G"
        />
      </filter>
    </defs>
  </svg>
</template>
