<script setup lang="ts">
const scrollbarWidth = ref("0px");

function getScrollbarWidth() {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  return `${Math.round(scrollbarWidth / 2)}px`;
}

watch(scrollbarWidth, (value) => {
  document.documentElement.style.setProperty("--scrollbar-width", value);
});

onMounted(() => {
  scrollbarWidth.value = getScrollbarWidth();

  window.addEventListener("resize", () => {
    scrollbarWidth.value = getScrollbarWidth();
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    scrollbarWidth.value = getScrollbarWidth();
  });
});
</script>

<template>
  <span data-purpose="scrollbar-measurement" />
</template>
